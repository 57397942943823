import React from 'react'
import { NavLink } from "react-router-dom";
// import logo from '../../images/logo.jpeg';

function Header() {
  
  return (
    <header id="masthead" className="header prt-header-style-01">
        <div className="top_bar bg-base-dark clearfix">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="top_bar_contact_item">
                                    <div className="top_bar_icon"><i className="fa-sharp fa-solid fa-location-dot"></i></div>
                                    <div className="top_bar_content">4, Pashupati Bhattacharjee Road, Kolkata 700034</div>
                                </div>
                                <div className="top_bar_contact_item">
                                    <div className="top_bar_icon"><i className="fa-sharp fa-solid fa-clock"></i></div>
                                    <div className="top_bar_content">Mon-Sat: 9:30 AM to 6:00 PM</div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contact-info">
                                    <div className="top_bar_contact_item prt-highlight-right">
                                        <div className="top_bar_icon"><i className="fa fa-envelope-o"></i></div>
                                        <div className="top_bar_content"><a href="mailto:cngepl@gmail.com"><strong>cngepl@cnkbharat.com</strong></a></div>
                                    </div>
                                    <div className="top_bar_content-main">Follow On :</div>
                                    <div className="top_bar_contact_item top_bar_social ml-auto p-0">
                                        <ul className="social-icons d-flex">
                                            <li>
                                                <a className="prt-social-facebook" href="https://business.facebook.com/latest/home?asset_id=107582702197479&nav_ref=professional_dashboard_insights_home_mbs_upsell" rel="noopener" aria-label="facebook"><i className="fab fa-facebook-f"></i></a></li>
                                            <li>
                                                <a className="prt-social-twitter" href="https://twitter.com/cnkbharat" rel="noopener" aria-label="twitter"><i className="fa-brands fa-twitter"></i></a></li>
                                            <li>
                                                <a className="prt-social-linkedin" href="https://www.linkedin.com/company/93801163/admin/" rel="noopener" aria-label="google"><i className="fa-regular fa-envelope"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="site-header-menu" className="site-header-menu bg-base-white">
            <div className="site-header-menu-inner prt-stickable-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="site-navigation d-flex flex-row  justify-content-between align-items-center">
                                <div className="site-branding ">
                                    <h1>
                                    <NavLink to="/" className="home-link" title="Elexio" rel="home">
                                        
                                        <img id="logo-img" height="35" width="155" className="img-fluid auto_size" src="../assets/images/main-logo.png" alt="logo-img" />
                                    </NavLink>
                                    </h1>
                                </div>
                                <div className="btn-show-menu-mobile menubar menubar--squeeze">
                                    <span className="menubar-box">
                                        <span className="menubar-inner"></span>
                                    </span>
                                </div>
                                <nav className="main-menu menu-mobile ms-auto" id="menu">
                                    <ul className="menu">
                                        <li className="mega-menu-item">
                                            <NavLink to="/" className="">Home</NavLink>
                                        </li>
                                        <li className="mega-menu-item">
                                            <NavLink to="/about" className="">About us</NavLink>
                                        </li>
                                        <li className="mega-menu-item">
                                            <NavLink to="/services" className="">Services</NavLink>
                                        </li>
                                        <li className="mega-menu-item">
                                            <NavLink to="/products" className="">Products</NavLink>
                                        </li>
                                        <li className="mega-menu-item">
                                            <NavLink to="/blog" className="">Blog</NavLink>
                                        </li>
                                        <li className="mega-menu-item">
                                            <NavLink to="/contact" className="">Contact Us</NavLink>
                                        </li>
                                    </ul>
                                </nav>
                                <div className=" d-flex flex-row">
                                    <div className="header_extra d-flex flex-row align-items-center justify-content-end">
                                        <div className="header_btn">
                                            <a className="prt-btn prt-btn-size-sm prt-btn-shape-round prt-btn-style-fill prt-icon-btn-right prt-btn-color-skincolor" href="#quote-form">GET A QUOTE</a>
                                        </div>
                                    </div>
                                    <div className="widget_info d-flex flex-row align-items-center justify-content-end">
                                        <div className="widget_icon text-base-skin"><i className="fa-sharp fa-solid fa-phone-volume"></i></div>
                                        <div className="widget_content">
                                            <p className="widget_desc">Call Us</p>
                                            <h3 className="widget_title"><a href="tel:1234567890">+91 9051257814</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
  )
}

export default Header