import React from 'react'
import Header from '../../includes/Header';
import DetailBanner from './DetailBanner'
import Details from './Details';
import Footer from '../../includes/Footer'

function BlogDetail() {
  return (
    <div>
        <div className="page">
            <Header />
            <DetailBanner />
            <Details />
            <Footer />
            
            <a id="totop" href="#top">
                <i className="fa fa-angle-up"></i>
            </a>

        </div>
    </div>
  )
}

export default BlogDetail