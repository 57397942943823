import React from 'react'
import { NavLink } from "react-router-dom";

import oak from '../../images/products/oak.jpeg';
import maple from '../../images/products/maple.jpeg';
import pinus from '../../images/products/pinus.jpeg';
import cedar from '../../images/products/cedar.jpeg';

function ProductSection() {
  const productImgHeight = {
    height: "401px",
  };

  return (
    <section className="prt-row grid-section clearfix">
        <div className="container">
          {/* row */}
          <div className="row mb_15 mt_15">
            <div className="col-lg-3 col-md-3 col-sm-6">
              {/* featured-imagebox-portfolio */}
              <div className="featured-imagebox featured-imagebox-portfolio style2">
                {/* prt-box-view-overlay */}
                <div className="prt-box-view-overlay prt-portfolio-box-view-overlay">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail"> 
                    <img className="img-fluid" src={pinus} alt="image"  style={productImgHeight} /> 
                  </div>
                  {/* featured-thumbnail end*/}
                  {/* <div className="prt-media-link">
                    <a className="prt_prettyphoto prt_image" data-gal="prettyPhoto[gallery1]" title="Silk Fabric" href="images/portfolio/portfolio-2-1200x800.jpg" data-rel="prettyPhoto" tabIndex={0}>
                      <i className="fa fa-search" />
                    </a>
                    <a href="portfolio-single.html" className="prt_link" tabIndex={0}><i className="ti ti-link" /></a>
                  </div> */}
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3><NavLink to="/products/pinus">Pinus</NavLink></h3>
                  </div>
                  <div className="featured-desc">
                    <p>3.3 Kw used for charging 2,3,4 wheelers</p>
                  </div>
                </div>
              </div>{/* featured-imagebox-portfolio end*/}
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              {/* featured-imagebox-portfolio */}
              <div className="featured-imagebox featured-imagebox-portfolio style2">
                {/* prt-box-view-overlay */}
                <div className="prt-box-view-overlay prt-portfolio-box-view-overlay">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail"> 
                    <img className="img-fluid" src={oak} alt="image" style={productImgHeight} /> 
                  </div>
                  {/* featured-thumbnail end*/}
                  {/* <div className="prt-media-link">
                    <a className="prt_prettyphoto prt_image" data-gal="prettyPhoto[gallery1]" title="Silk Fabric" href="images/portfolio/portfolio-2-1200x800.jpg" data-rel="prettyPhoto" tabIndex={0}>
                      <i className="fa fa-search" />
                    </a>
                    <a href="portfolio-single.html" className="prt_link" tabIndex={0}><i className="ti ti-link" /></a>
                  </div> */}
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3><NavLink to="/products/oak">Oak</NavLink></h3>
                  </div>
                  <div className="featured-desc">
                    <p>4.6 Kw moderate charger for charging 4 Wheelers</p>
                  </div>
                </div>
              </div>{/* featured-imagebox-portfolio end*/}
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              {/* featured-imagebox-portfolio */}
              <div className="featured-imagebox featured-imagebox-portfolio style2">
                {/* prt-box-view-overlay */}
                <div className="prt-box-view-overlay prt-portfolio-box-view-overlay">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <a href="#"> <img className="img-fluid" src={cedar} alt="image" style={productImgHeight} /></a>
                  </div>{/* featured-thumbnail end*/}
                  {/* <div className="prt-media-link">
                    <a className="prt_prettyphoto prt_image" title="Leather Material" data-rel="prettyPhoto" href="images/portfolio/portfolio-4-1200x800.jpg">
                      <i className="fa fa-search" />
                    </a>
                    <a href="portfolio-single.html" className="prt_link"><i className="ti ti-link" /></a>
                  </div> */}
                </div>{/* prt-box-view-overlay end*/}
                <div className="featured-content">
                  <div className="featured-title">
                    <h3><NavLink to="/products/cedar">Cedar</NavLink></h3>
                  </div>
                  <div className="featured-desc">
                    <p>7.5 Kw Moderate charger for charging 4 Wheelers</p>
                  </div>
                </div>
              </div>{/* featured-imagebox-portfolio */}
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              {/* featured-imagebox-portfolio */}
              <div className="featured-imagebox featured-imagebox-portfolio style2">
                {/* prt-box-view-overlay */}
                <div className="prt-box-view-overlay prt-portfolio-box-view-overlay">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <a href="#"> <img className="img-fluid" src={maple} alt="image" style={productImgHeight} /></a>
                  </div>{/* featured-thumbnail end*/}
                  {/* <div className="prt-media-link">
                    <a className="prt_prettyphoto prt_image" title="Georgette Fabric" data-rel="prettyPhoto" href="images/portfolio/portfolio-5-1200x800.jpg">
                      <i className="fa fa-search" />
                    </a>
                    <a href="portfolio-single.html" className="prt_link"><i className="ti ti-link" /></a>
                  </div> */}
                </div>{/* prt-box-view-overlay end*/}
                <div className="featured-content">
                  <div className="featured-title">
                    <h3><NavLink to="/products/maple">Maple</NavLink></h3>
                  </div>
                  <div className="featured-desc">
                    <p>22 Kw Fast Charger for 4 wheelers.</p>
                  </div>
                </div>
              </div>{/* featured-imagebox-portfolio */}
            </div>
          </div>
        </div>
      </section>
  )
}

export default ProductSection