import React from 'react'

function DetailBanner() {
  return (
    <div>
      
        {/* page-title */}
        <div className="prt-page-title-row">
          <div className="prt-page-title-row-inner">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="page-title-heading">
                    <h2 className="title">Blog Single View</h2>
                  </div>
                  <div className="breadcrumb-wrapper-main">
                    <div className="container">
                      <div className="breadcrumb-wrapper-inner bread-white">
                        <span>
                          <a href="index.html" className="home">&nbsp;&nbsp;Home</a>
                        </span>
                        <span>
                          <a href="blog.html" className="home">&nbsp;&nbsp;-&nbsp;&nbsp;Blog</a>
                        </span>
                        <span className="prt-bread-sep">&nbsp; - &nbsp;</span>
                        <span>Blog Single View</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>                    
        </div>
    </div>
  )
}

export default DetailBanner