import React from 'react'
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import Slider from '../includes/Slider';
import HomeSection from './HomeSection';

function Home() {
  return (
    <div>
        <div className="page">
            <div className="min-box">
                <Header />
                <Slider />
            </div>

            <HomeSection />
            <Footer />
            
            <a id="totop" href="#top">
                <i className="fa fa-angle-up"></i>
            </a>

        </div>
    </div>
  )
}

export default Home