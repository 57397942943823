import React from 'react';
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <footer className="widget-footer bg-base-grey clearfix">
        <div className="second-footer prt-bgimage-yes bg-footer prt-bg">
            <div className="prt-row-wrapper-bg-layer prt-bg-layer"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-6 col-lg-4 widget-area">
                        <div className="widget widget_text clearfix">
                            <h3 className="widget-title">About <span className="logo_txt_primary">cnk</span><span className="logo_txt_secondary">bharat</span></h3>
                            <div className="textwidget widget-text pb-10">
                                <p>We are an emerging EV Charger Manufacturing & Services Providing Unit in Eastern India.</p>
                            </div>
                            <div className="textwidget widget-text">
                                <ul className="widget_contact_wrapper pl-0 res-575-pb-0">
                                    <li><strong>Email:</strong> <a href="mailto:info@example.com"><strong>cngepl@cnkbharat.com</strong></a></li>
                                    <li><strong>Address:</strong> 4, Pashupati Bhattacharjee Road, Kolkata - 700034, West Bengal, India</li>
                                </ul>      
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-4 widget-area">
                        <div className="widget widget_nav_menu clearfix">
                            <h3 className="widget-title">Our Services</h3>
                            <ul id="menu-footer-quick-links">
                                <li><a href="#">AC Charger Services</a></li>
                                <li><a href="#">Charge Point Services</a></li>
                                <li><a href="#">Building Services</a></li>
                                <li><a href="#">Vehicle Chargers</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-4 widget-area">
                        <div className="widget widget_nav_menu clearfix">
                            <h3 className="widget-title">Useful Links</h3>
                            <ul id="menu-footer-quick-links-main">
                                {/* <li><a href="#">AC Charger Services</a></li>
                                <li><a href="#">Charge Point Services</a></li>
                                <li><a href="#">Building Services</a></li>
                                <li><a href="#">Home Charging</a></li>
                                <li><a href="#">AC Charger Services</a></li>
                                <li><a href="#">Charge Point Services</a></li> */}
                                <li><NavLink to="/term-condition" className="">Terms & Condition</NavLink></li>
                                <li><NavLink to="/privacy-policy" className="">Privacy Policy</NavLink></li>
                                <li><NavLink to="/refunds-cancellation" className="">Refunds & Cancellation</NavLink></li>
                                <li><NavLink to="/pricing" className="">Pricing Policy</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bottom-footer-text text-center text-base-white copyright">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="justify-content-between">
                            <span className="cpy-text text-center">Copyright © 2023 <a href="index.html"> <span className="logo_txt_primary">cnk</span><span className="logo_txt_secondary">bharat</span> </a>. All Rights Reserved.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer