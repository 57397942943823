import React from 'react'
import { NavLink } from "react-router-dom";

import blogData from './blogData';

function BlogSection() {

  let blogList = [];
  blogData.blogs.map((data, i) => {
    console.log(data, i)
    let imgPath = './blogImages/blog'+(i+1)+'.png';
    let images = require(`${imgPath}`)
    blogList.push(
        <div className="col-lg-4 col-md-6 col-sm-6" key={i}>
          {/* featured-imagebox-post */}
          <div className="featured-imagebox featured-imagebox-post style2">
            <div className="prt-box-post-date">
              <span className="prt-entry-date">
                <time className="entry-date" dateTime="2021-05-18T04:16:25+00:00">21<span className="entry-month entry-year">May</span></time>
              </span>
            </div>
            <div className="featured-thumbnail">
              <img className="img-fluid" src={images} alt="blog_img" />
            </div>
            <div className="featured-content">
              <div className="post-meta">
                <span className="prt-meta-line byline">
                  <NavLink to={`/${i}`} tabIndex={i}>Brands</NavLink>
                </span>
                <span className="prt-meta-line comments-link">
                  <i className="flaticon-user" /><NavLink to={`/${i}`} tabIndex={i}>Admin</NavLink>
                </span>      
              </div>   
              <div className="post-title featured-title">
                <h3><NavLink to={`/${i}`}  tabIndex={i}>{data.heading}</NavLink>
                </h3>
              </div>         
            </div>
          </div>{/* featured-imagebox-post end */}
        </div>
      );
  });
  return (
    <div className="site-main">
        {/*grid-section*/}
        <section className="prt-row grid-section clearfix">
          <div className="container">
            {/* row */}
            <div className="row">
              {blogList}
            </div>
            {/* row end */}
          </div>
        </section>
        {/*grid-section end*/}
    </div>
  )
}

export default BlogSection