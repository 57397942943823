import React from 'react'

function BlogHeader() {
  return (
    <div className="prt-page-title-row">
        <div className="prt-page-title-row-inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="page-title-heading">
                  <h2 className="title">Blog</h2>
                </div>
                <div className="breadcrumb-wrapper-main">
                  <div className="container">
                    <div className="breadcrumb-wrapper-inner">
                      <span>
                        <a href="index.html" className="home">&nbsp;&nbsp;Home</a>
                      </span>
                      <span className="prt-bread-sep">&nbsp; - &nbsp;</span>
                      <span>Blog</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>                    
    </div>
  )
}

export default BlogHeader