import React from 'react';

function PricingSection() {
    
  return (
        
        <div className="site-main">

            <section className="prt-row about-section clearfix">
                <div className="container" style={{marginTop: '100px'}}>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="res-1400-pl-15 res-1199-ml-30 res-991-pt-30 pl-15 pt-25 res-480-ml-0 res-480-pl-0">
                                
                                <div className="section-title">
                                    <div className="">
                                        <h3 style={{fontSize: "20px"}}>Pricing Policy</h3>
                                    </div>
                                    <div className="title-desc">
                                        <p style={{fontSize: "16px"}}>This Pricing Policy outlines the terms and conditions governing the pricing of products and services offered by C&N Green Energy Pvt. Ltd. (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) on <strong><a href='https://cnkbharat.com/' target='_blank' rel="noreferrer">www.cnkbharat.com</a></strong> (the &quot;Website&quot;). By making a purchase on the Website, you agree to the terms of this Pricing Policy.</p>
                                        
                                        <h4>Pricing</h4>
                                        <ul>
                                            <li>Product Prices: The prices of products displayed on the Website are quoted in Rs. and are subject to change without notice. Prices may vary depending on factors such as location, promotions, and availability.</li>
                                            <li>Installation Fees: The fees for services provided by us are determined based on the scope of work, duration, and any additional services requested by the customer. Service fees will be clearly communicated to the customer before the commencement of the service.</li>
                                        </ul>

                                        <h4>Taxes and Fees</h4>
                                        <ul>
                                            <li>GST as Applicable: Prices listed on the Website may exclude applicable sales tax, value-added tax (GST as Applicable), or other taxes and fees, which will be added to your total purchase price at checkout, if applicable.</li>
                                            <li>Additional Fees: In addition to the listed prices, customers may be responsible for additional fees such as shipping fees, handling fees, or transaction fees, which will be disclosed to the customer before completing the purchase.</li>
                                        </ul>

                                        <h4>Discounts and Promotions</h4>
                                        <ul>
                                            <li>Discounts: From time to time, we may offer discounts or promotions on certain products or services. The terms and conditions of any discounts or promotions will be clearly stated, including any expiration dates or eligibility criteria.</li>
                                            <li>Coupon Codes: Coupon codes may be provided for use at checkout to receive a discount on eligible products or services. Coupon codes cannot be combined with other offers unless otherwise specified.</li>
                                        </ul>

                                        <h4>Payment Terms</h4>
                                        <ul>
                                            <li>Payment Methods: We accept various forms of payment, including credit cards, debit cards, and electronic payment methods. Payment must be made in full at the time of purchase, unless otherwise agreed upon by us.</li>
                                            <li>Authorization: By providing your payment information, you authorize us to charge the designated payment method for the total amount of your purchase, including any applicable taxes and fees.</li>
                                        </ul>

                                        <h4>Price Changes</h4>
                                        <ul>
                                            <li>Adjustments: We reserve the right to adjust prices for products and services offered on the Website at any time, without prior notice. Price adjustments will not affect the price of products or services that have already been purchased.</li>
                                        </ul>

                                        <h4>Contact Us</h4>
                                        <ul>
                                            <li>If you have any query about this Pricing Policy, please contact us at <strong>cngepl@cnkbharat.com</strong>.</li>
                                        </ul>

                                        
                                        <p>By making a purchase on the Website, you acknowledge that you have read, understood, and agree to the terms of this Pricing Policy.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            

        </div>
  )
}

export default PricingSection