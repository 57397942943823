import React from 'react'

function ContactHeaderBanner() {
  return (
    <div className="prt-page-title-row-3">
        <div className="prt-page-title-row-inner-3">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="page-title-heading">
                            <h2 className="title">Contact Us</h2>
                        </div>
                        <div className="breadcrumb-wrapper-main">
                            <div className="container">
                                <div className="breadcrumb-wrapper-inner">
                                    <span>
                                        <a href="index.html" className="home">&nbsp;&nbsp;Home</a>
                                    </span>
                                    <span className="prt-bread-sep">&nbsp; - &nbsp;</span>
                                    <span>Contact Us</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>                    
    </div>
  )
}

export default ContactHeaderBanner