import React, { useState } from 'react';
import OurTeams from './OurTeams';
import Video from '../../videos/presentation.MOV';

function AboutSection() {
    const [activeItem, setActiveItem] = useState('vision');
  
    const showSection = (divName) => {

        setActiveItem(divName);

        let missionDiv = document.querySelector('.ourMissionDiv');
        let goalDiv = document.querySelector('.ourGoalsDiv');
        let visionDiv = document.querySelector('.ourVisionDiv');
        if(divName == 'vision') {
            if(visionDiv != undefined) {
                missionDiv.classList.add("d-none")
                goalDiv.classList.add("d-none")
                visionDiv.classList.remove("d-none")
            }
        } else if(divName == 'mission') {
            if(missionDiv != undefined) {
                visionDiv.classList.add("d-none")
                goalDiv.classList.add("d-none")
                missionDiv.classList.remove("d-none")
            }
        } else if(divName == 'goals') {
            if(goalDiv != undefined) {
                visionDiv.classList.add("d-none")
                missionDiv.classList.add("d-none")
                goalDiv.classList.remove("d-none")
            }
        }
    }
    
  return (
        
        <div className="site-main">

            <section className="prt-row about-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="prt_single_image-wrapper imagestyle-one res-767-pr-50">
                                {/* <div className="prt_single_image_text prt-bgcolor-skincolor">25 Years Of Experience!</div> */}
                                <img className="img-fluid auto_size" src="../assets/images/single-img-5.jpg" alt="single-5" width="632" height="532" />
                            </div>
                        </div> 
                        <div className="col-lg-6 col-md-12">
                            <div className="res-1400-pl-15 res-1199-ml-30 res-991-pt-30 pl-15 pt-25 res-480-ml-0 res-480-pl-0">
                                
                                <div className="section-title">
                                    <div className="">
                                        <h3 style={{fontSize: "20px"}}>About Us</h3>
                                        {/* <h2 className="title">Our mission is to put an electric vehicle charge</h2> */}
                                    </div>
                                    <div className="title-desc">
                                        <p style={{fontSize: "16px"}}>C &amp; N Green energy Pvt Ltd is a Kolkata Based Start Up, Manufacturing EV Chargers for 2,3,4 Wheelers under the Brand Name of <span className="logo_txt_primary">cnk</span><span className="logo_txt_secondary">bharat</span>.</p>
                                        
                                        <p style={{fontSize: "16px"}}>We are an emerging EV Charger Manufacturing &amp; Services Providing Unit in Eastern India.</p>
                                        
                                        <p style={{fontSize: "16px"}}>In the EV Charger Market, <span className="logo_txt_primary">cnk</span><span className="logo_txt_secondary">bharat</span> offers total EV charging solutions in compact, High-Quality AC Wall Boxes and are gradually venturing into reliable DC Fast-Charging Stations with Robust Connectivity &amp; Innovative on-demand EV 4 wheelers, bus & trucks.</p>
                                        
                                        <p style={{fontSize: "16px"}}>Our Motto is reflected by our Tagline <span style={{color: "#7ab629"}}>“Solutions for Green Planet”</span>. Our young and dedicated team of Engineers are committed to counter the effects of Climate Change by providing Sufficient Charging Options for people switching from Fossil Fuel Powered Vehicles to Electric Vehicles thus cutting down Carbon Emissions of our dear Planet.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="prt-tabs prt-tab-style-01" style={{marginBottom: "20px"}} >
                <ul className="tabs">
                    <li className={activeItem === 'vision' ? 'tab plans active' : 'tab plans'} style={{ paddingRight: "25px" }} onClick={() => showSection("vision")}>Our Vision</li>
                    <li className={activeItem === 'mission' ? 'tab plans active' : 'tab plans'} style={{ paddingRight: "25px" }} onClick={() => showSection("mission")}>Our Mission</li>
                    <li className={activeItem === 'goals' ? 'tab plans active' : 'tab plans'} style={{ paddingRight: "25px" }} onClick={() => showSection("goals")}>Our Goals</li>
                </ul>
                <div className="content-tab">
                    <div className="content-inner active">
                        <div className="row ourVisionDiv">
                            <div className="offset-md-3 col-sm-6">
                                <p style={{fontSize: "16px", textAlign: "left"}}>C&N vision is to design, manufacture and promote indigenously developed Plug-In Electric Vehicle Charging solutions for Homes, Residential Complexes, Offices, Restaurants, Clubs, Shopping Malls, Public Car Parking Areas, Petrol Pumps and Car Service Stations.</p>
                            </div>
                        </div>
                        <div className="row ourMissionDiv d-none">
                            <div className="offset-md-3 col-sm-6">
                                <p style={{fontSize: "16px", textAlign: "left"}}>At C&amp;N, our Mission is to develop innovative, environmentally sustainable solutions by manufacturing and providing for Plug-In-Electric Vehicle Chargers at reasonable cost for benefit of People and Nature and create Low-Carbon, Zero-Emission transport. Thus Bringing Down The Pollutant &amp; Pollution Levels in Environment to help counter Climate Change.</p>
                            </div>
                        </div>
                        <div className="row ourGoalsDiv  d-none">
                            <div className="offset-md-3 col-sm-6">
                                <ul className="list-group" style={{textAlign: "left"}}>
                                    <li><i className="fa fa-check-square" style={{marginRight: "16px"}}></i><span className="prt-list-li-content" style={{fontSize: "16px"}}>Help Build Charging Infrastructure to meet the Demand Supply Mismatch with respect to the e-Vehicles plying on Roads &amp; the Chargers points.</span></li><br/>
                                    <li><i className="fa fa-check-square"></i><span className="prt-list-li-content" style={{fontSize: "16px"}}>At C&amp;N, our Mission is to develop innovative, environmentally sustainable solutions by manufacturing and providing for Plug-In-Electric Vehicle Chargers at reasonable cost for benefit of People and Nature and create Low-Carbon, Zero-Emission transport.</span></li><br/>
                                    {/* <li><i className="fa fa-check-square"></i><span className="prt-list-li-content" style={{fontSize: "16px"}}>At C&amp;N, our Mission is to develop innovative, environmentally sustainable solutions by manufacturing and providing for Plug-In-Electric Vehicle Chargers at reasonable cost for benefit of People and Nature and create Low-Carbon, Zero-Emission transport.</span></li><br/> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <section className="prt-row cta-section-2 prt-bgimage-yes bg-img2 prt-bg bg-base-dark clearfix">
                <div className="prt-row-wrapper-bg-layer prt-bg-layer"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 m-auto">
                            
                            <div className="section-title text-center">
                                <div className="title-header res-991-pl-0">
                                    <h3>Let’s drive the future</h3>
                                    <h2 className="title">There’s nothing like the fresh air of our <span className="text-base-skin text-decoration-underline"> electric </span> drive <span className="text-base-skin text-decoration-underline"> cars!</span></h2>
                                </div>
                            </div>
                            <div className="row mt-30 cta-content">
                                <div className="col-md-12">
                                    <div className="prt_single_image-wrapper position-relative">
                                        {/* <img className="img-fluid" src="../assets/images/single-img-4.jpg" alt="img" />
                                        <div className="prt-play-icon-btn style1 animated">
                                            <div className="prt-play-icon-animation">
                                                <a href="https://youtu.be/7e90gBu4pas" target="_self" className="prt_prettyphoto">
                                                    <div className="prt-icon prt-icon_element-fill prt-icon_element-color-white prt-icon_element-size-xs prt-icon_element-style-rounded">
                                                        <i className="fa fa-play"></i>
                                                    </div>
                                                </a>
                                            </div>
                                        </div> */}
                                        <video src={Video} style={{width: "400px", height: "200px"}} controls="controls" autoPlay="false" />
                                    </div>
                                </div>
                                {/* <div className="col-md-6 res-767-mt-20 res-1170-ml_40 res-991-ml-0">
                                    <div className="section-title res-767-mb-0">
                                        <div className="title-header pl-0 mb-0">
                                            <h3 className="title fs-22">Watch Our Videos</h3>
                                        </div>
                                    </div>
                                    <div className="title-desc res-767-mb-0">
                                        <p>Best performance to drive</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="prt-row bg-base-skin mt_20 pb-70 res-991-m-0 clearfix">
                <div className="container">
                    <div className="row no-gutters ml-20">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="prt-fid inside style1 res-991-pb-100">
                                <div className="prt-fid-contents text-base-white">
                                    <h4 className="prt-fid-inner">
                                        <span   data-appear-animation="animateDigits" 
                                                data-from="0" 
                                                data-to="12" 
                                                data-interval="1" 
                                                data-before="" 
                                                data-before-style="sup" 
                                                data-after="+"
                                                data-after-style="sub" 
                                                className="numinate">1</span>
                                    </h4>
                                    <span className="num">1</span>
                                    <h3 className="prt-fid-title">Years Of <br /> Experience </h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            
                            <div className="prt-fid inside style1 res-991-pb-100">
                                <div className="prt-fid-contents text-base-white">
                                    <h4 className="prt-fid-inner">
                                        <span   data-appear-animation="animateDigits" 
                                                data-from="0" 
                                                data-to="20" 
                                                data-interval="1" 
                                                data-before="" 
                                                data-before-style="sup" 
                                                data-after="+"
                                                data-after-style="sub" 
                                                className="numinate">20</span>
                                    </h4>
                                    <span className="num">20</span>
                                    <h3 className="prt-fid-title"> Charging <br/> Points </h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="prt-fid inside style1 res-575-pb-100">
                                <div className="prt-fid-contents text-base-white">
                                    <h4 className="prt-fid-inner">
                                        <span   data-appear-animation="animateDigits" 
                                                data-from="0" 
                                                data-to="9" 
                                                data-interval="1" 
                                                data-before="" 
                                                data-before-style="sup" 
                                                data-after="+"
                                                data-after-style="sub" 
                                                className="numinate">1</span><span>k</span>
                                    </h4>
                                    <span className="num">1k</span>
                                    <h3 className="prt-fid-title"> Clients <br /> Review </h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="prt-fid inside style1">
                                <div className="prt-fid-contents text-base-white">
                                    <h4 className="prt-fid-inner">
                                        <span   data-appear-animation="animateDigits" 
                                                data-from="0" 
                                                data-to="95" 
                                                data-interval="10" 
                                                data-before="" 
                                                data-before-style="sup" 
                                                data-after="+"
                                                data-after-style="sub" 
                                                className="numinate">95</span>
                                    </h4>
                                    <span className="num">95</span>
                                    <h3 className="prt-fid-title"> Happy <br /> Members </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <OurTeams />

        </div>
  )
}

export default AboutSection