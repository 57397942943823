import React from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import ContactHeaderBanner from './ContactHeaderBanner'
import ContactSection from './ContactSection'

function Contactus() {
  return (
    <div className='page'>
        <Header />
        <ContactHeaderBanner />
        <ContactSection />
        <Footer />
    </div>
  )
}

export default Contactus