import React from 'react'
import Header from '../../includes/Header'
import ProductHeaderBanner from '../ProductHeaderBanner'
import ProductDetails from './ProductDetails'
import Footer from '../../includes/Footer'
import {useParams} from "react-router-dom"

function Product() {
    let params = useParams();
    console.log(params);
    return (
        <div className='page'>
            <Header />
            <ProductHeaderBanner />
            <ProductDetails />
            <Footer />
        </div>
    )
}

export default Product