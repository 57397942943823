import {React, useState, useEffect, useRef} from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function HomeSection() {
    const ulListAlignment = {
        textAlign : "left",
        marginLeft : "10px"
    };

    // const [index, setIndex] = useState(0);

    // const handleSelect = (selectedIndex, e) => {
    //     setIndex(selectedIndex);
    // };

    const initialValue = { username: "", email: "", number: "", message: ""};
    const [formValues, setFormValues] = useState(initialValue);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({ ...formValues, [name]: value });
        // console.log(formValues)
    }

    const formRefHome = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    }

    const notify = () => toast.success("Congratulation, message has sent successfully");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let url = "https://api.cnkbharat.com/api/contact-mail";
        if(Object.keys(formErrors).length === 0 && isSubmit) {
            setIsLoading(true);
            axios.post(url, {
                name: document.getElementById('username').value,
                email: document.getElementById('email').value,
                mobile: document.getElementById('number').value,
                message: document.getElementById('message').value,
            })
            .then(() => {
                setIsLoading(false);
                // formRefHome.current.reset();
                // document.getElementById('username').value='';
                // document.getElementById('email').value='';
                // document.getElementById('number').value='';
                // document.getElementById('message').value='';
                setFormValues(initialValue);
                notify();
            });
            
        }
    }, [formErrors]);


    const validate = (values) => {
        const errors = {};
        // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if(!values.username) {
            errors.username = "Name is required";
        }
        if(!values.email) {
            errors.email = "Email is required";
        }
        if(!values.number) {
            errors.number = "Number is required";
        }
        if(!values.message) {
            errors.message = "Message is required";
        }

        return errors
    }

  return (
    <div className="site-main">
                
        <section className="prt-row about-us-section padding_top_zero-section bg-base-grey prt-bg prt-bgimage-yes bg-img1 clearfix">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="prt-bg prt-col-bgimage-yes bg-base-white prt-right-span mt_70 res-991-ml_15 spacing-1">
                            <div className="prt-col-wrapper-bg-layer prt-bg-layer"></div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="prt_single_image-wrapper text-md-center res-991-ml-15">
                                        {/* <div className="prt_single_image_text prt-bgcolor-skincolor">25 Years Of Experience</div> */}
                                        <img className="img-fluid auto_size" src="../assets/images/single-img-1.jpg" width="586" height="581" alt="single-1"/>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="pl-15 pt-25">
                                        
                                        <div className="section-title">
                                            <div className="title-header">
                                                <h2 className="title">Benefit of EV in our environment</h2>
                                            </div>
                                            <div className="title-desc">
                                                <p>Build Charging Infrastructure to meet the Demand Supply Mismatch with respect to the e-Vehicles plying on Roads &amp; the Chargers available</p>
                                                <p>Enhance Charging Experience by providing Charger Access through RFID Cards, QR Code Scan &amp; Mobile App. Pay Online, Get information about the Power Consumed during Charging &amp; its subsequent Payble Amount.</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                
                                                <div className="featured-icon-box icon-align-before-content">
                                                    
                                                    <div className="featured-content">
                                                        <div className="featured-desc">
                                                            <h3 className="fs-21">We’re In this Business Since <span className="text-base-skin text-decoration-underline">2021</span> And We Provide The Best EV Services.</h3>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                        <div className="prt-horizontal_sep width-100 pt-40 res-575-pt-25"></div>
                                        <div className="row prt-vertical_sep">
                                            <div className="col-sm-6">
                                                <ul className="prt-list prt-list-style-icon d-grid prt-list-icon-color-skincolor">
                                                    <li><strong><i className="fa fa-check-square"></i><span style={ulListAlignment}>Reduce Pollution</span></strong></li>
                                                    <li><strong><i className="fa fa-check-square"></i><span style={ulListAlignment}>Reduce Import of Fossil Fuel</span></strong></li>
                                                    <li><strong><i className="fa fa-check-square"></i><span style={ulListAlignment}>Help Counter Climate Change</span></strong></li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6 pl-30 res-575-pl-15">
                                                <ul className="prt-list prt-list-style-icon d-grid prt-list-icon-color-skincolor">
                                                    <strong><li><i className="fa fa-check-square"></i><span style={ulListAlignment}>Low Maintenance Cost</span></li></strong>
                                                    <strong><li><i className="fa fa-check-square"></i><span style={ulListAlignment}>Help Accelerate switch from ICE to EV</span></li></strong>
                                                    <strong><li><i className="fa fa-check-square"></i><span style={ulListAlignment}>Balance EV and charger Demand Supply Mismatch</span></li></strong>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section className="prt-row mb_10 clearfix">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 m-auto">
                        
                        <div className="section-title title-style-center_text">
                            <div className="title-header prt-textcolor-white">
                                <h3>Our Services</h3>
                                <h2 className="title">We are best service Provider for the electric vehicle</h2>
                            </div>                        
                        </div>
                    </div>
                </div>
                <div className="row slick_slider slick-arrows-style2 " data-slick='{"slidesToShow": 4, "slidesToScroll": 1, "arrows":false, "autoplay":false, "dots":false, "infinite":true, "responsive":[{"breakpoint":1200,"settings":{"slidesToShow": 3}},{"breakpoint":992,"settings":{"slidesToShow": 2}},{"breakpoint":650,"settings":{"slidesToShow": 1}}]}'>
                    <div className="col-md-6 col-sm-6">
                        
                        <div className="featured-imagebox featured-imagebox-services style1">
                            
                            <div className="featured-thumbnail">
                                <img width="327" height="255" className="img-fluid" src="../assets/images/services/services-328x259.jpg" alt="image"/>
                            </div> 
                            <div className="featured-content">
                                <div className="featured-icon">
                                    <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-color-skincolor prt-icon_element-size-xl">
                                        <i className="flaticon-wireless-charging"></i>
                                    </div>
                                </div>
                                <div className="featured-title">
                                    <h3><a href="prawn-feeds.html">Installation</a></h3>
                                </div>
                                <div className="featured-desc">
                                    {/* <p>It Imperative keep up with tire rotation manual’s recommendation.</p> */}
                                </div>
                            </div>
                            {/* <div className="featured-btn">
                                <a className="prt-btn btn-inline prt-btn-size-md prt-icon-btn-right" href="battery-maintenance.html">VIEW MORE</a>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        
                        <div className="featured-imagebox featured-imagebox-services style1">
                            
                            <div className="featured-thumbnail">
                                <img width="327" height="255" className="img-fluid" src="../assets/images/services/services-01-328x259.jpg" alt="image" />
                            </div>
                            <div className="featured-content">
                                <div className="featured-icon">
                                    <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-color-skincolor prt-icon_element-size-xl">
                                        <i className="flaticon-voice-control"></i>
                                    </div>
                                </div>
                                <div className="featured-title">
                                    <h3><a href="prawn-feeds.html">After Sales Services</a></h3>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col-lg-12 mx-auto">
                        <div className="mt-50 text-center">
                            <div className="end_txt_line">Challenges are Just Opportunities In Disguise. <a className="text-base-skin" href="#quote-form"> TAKE THE CHALLENGE</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="prt-row bg-img2 cta-section-1 bg-base-dark prt-bg prt-bgimage-yes clearfix">
            <div className="prt-row-wrapper-bg-layer prt-bg-layer"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="featured-icon-box icon-align-content style2">
                            <div className="featured-icon">
                                <div className="prt-icon prt-icon_element-size-lg prt-icon_element-color-skincolor prt-icon_element-style-round">
                                    <i className="flaticon-charging"></i>
                                    <span className="fea_num text-base-white">
                                        <i className="prt-num ti-info"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Competitive Pricing</h3>
                                </div>
                                <div className="featured-desc">
                                    <p>Our Unique Designs can help cater to the charging needs of all types of vehicles at Competitive Pricing.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="featured-icon-box icon-align-content style2">
                            <div className="featured-icon">
                                <div className="prt-icon prt-icon_element-size-lg prt-icon_element-color-skincolor prt-icon_element-style-round">
                                    <i className="flaticon-electric-car"></i>
                                    <span className="fea_num text-base-white">
                                        <i className="prt-num ti-info"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Indigenous technology</h3>
                                </div>
                                <div className="featured-desc">
                                    <p>We have built our Chargers based on our Indigenous Technology on the lines of Make In India.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="featured-icon-box icon-align-content style2">
                            <div className="featured-icon">
                                <div className="prt-icon prt-icon_element-size-lg prt-icon_element-color-skincolor prt-icon_element-style-round">
                                    <i className="flaticon-electric-power"></i>
                                    <span className="fea_num text-base-white">
                                        <i className="prt-num ti-info"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="featured-content">
                                <div className="featured-title">
                                    <h3>Robust Networking</h3>
                                </div>
                                <div className="featured-desc">
                                    <p>Starting from Kolkata, West Bengal we aim to further expand across pan India and export to all friendly countries.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="prt-row progress-section clearfix">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 align-self-center">
                        
                        <div className="section-title">
                            <div className="">
                                <h2 className="title">Our Products</h2>
                            </div>
                        </div>
                        <div className="row pt-20">
                            <div className="col-lg-12">
                                <img className="img-fluid" src="../assets/images/single-img-2.png" alt="single-06" />
                            </div>
                            <div className="col-lg-12 mt-5">
                                <p className="res-991-pt-30 pt-10"><strong><span className="logo_txt_primary">cnk</span><span className="logo_txt_secondary">bharat</span></strong> chargers are built to comply with  environment friendly mobility. We manufacture high-performance Chargers which are supported by Professional Installation, and Comprehensive After Sales Services. </p>
                            </div>
                            <div className="col-lg-12">
                                <p className="res-991-pt-30 pt-10">charging solution for your Commercial and Residential Charging requirements. We make charging Electric Vehicles Easy, Fast and Efficient. Our Product Quality & User Friendly Services will help create a lifelong Bond based on Trust.</p>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="bg-base-white pt-40 pb-20 pl-5 res-991-pl-0 res-991-pb-0">
                            <div className="accordion mt_5">
                                
                                <div className="toggle prt-toggle_style_classNameic style1 prt-toggle-title-bgcolor-grey">
                                    <div className="toggle-title"><a href="#" className="active">Which Charger is RIGHT for You ?
                                    </a></div>
                                    <div className="toggle-content show">
                                        <p>As per International Standards there are 3 Types of EV Chargers based on their Input Voltage namely:</p>
                                        <p>Type 1- Operational Input Voltage is 110-120 V & is mostly used Domestically.</p>
                                        <p>Type 2- Operational Input Voltage is 208-240 V & can be used both Domestically & Commercially.</p>
                                        <p>Direct Current (DC) Fast Chargers- Operational Input Voltage is 200-600 V &amp; is only used Commercially.</p>
                                    </div>
                                </div>
                                
                                <div className="toggle prt-toggle_style_classNameic style1 prt-toggle-title-bgcolor-grey">
                                    <div className="toggle-title"><a href="#">Visit our “Contact Us” page</a></div>
                                    <div className="toggle-content">
                                        <p>To schedule an appointment for telephonic discussion with our Expert Engineer or you may even schedule a site visit to allow us submit the best quoted price suitable for your requirement.</p>
                                    </div>
                                </div>
                                
                                <div className="toggle prt-toggle_style_classNameic style1 prt-toggle-title-bgcolor-grey">
                                    <div className="toggle-title"><a href="#">We are eager to send you details of our Products</a></div>
                                    <div className="toggle-content">
                                        <p>To help us know the Best Product as per your Requirement, here is a simple Form which can be filled up by you so that we can start our business dialogues at the earliest.</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            
        <section className="prt-row bg-base-grey service-section-2 clearfix">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 m-auto">
                        
                        <div className="section-title title-style-center_text">
                            <div className="title-header prt-textcolor-white">
                                <h3>Quote Us</h3>
                                <h2 className="title">Get Details & Consultation Over Electricity </h2>
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="prt-row bg-base-grey padding_top_zero-section clearfix" id="quote-form">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12 col-sm-12">
                        
                        <div className="prt-bg prt-col-bgimage-yes col-bg-img-one spacing-2" >
                            <div className="prt-col-wrapper-bg-layer prt-bg-layer"></div>
                            <div className="layer-content">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="bg-base-white border-rad_6 spacing-3">
                                        
                                        <div className="section-title style1 title-style-center_text text-start">
                                            <div className="title-header mb-10">
                                                <h2 className="title">Get a Free Quote</h2>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                                <div>Loading...</div>
                                            ) : (
                                                <form ref={formRefHome} id="prt-quote-form" className="prt-quote-form wrap-form clearfix" onSubmit={ handleSubmit }>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <span className="text-input">
                                                                    <input name="username" id="username" type="text" value= {formValues.username} placeholder="Your Name*" onChange={handleChange} />
                                                                    </span>
                                                            </label>
                                                            <p className='text-danger'><strong>{ formErrors.username }</strong></p>
                                                            <label>
                                                                <span className="text-input">
                                                                    <input name="email" id="email" type="text" value={formValues.email} placeholder="Email Address*" onChange={handleChange} />
                                                                </span>
                                                            </label>
                                                            <p className='text-danger'><strong>{ formErrors.email }</strong></p>
                                                            <label>
                                                                <span className="text-input">
                                                                    <input name="number" id="number" type="text" value={formValues.number} onChange={handleChange} placeholder="Phone Number" />
                                                                </span>
                                                            </label>
                                                            <p className='text-danger'><strong>{ formErrors.number }</strong></p>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <span className="text-input">
                                                                <textarea name="message" id="message" cols="40" rows="5" placeholder="Your Message" aria-required="true" onChange={handleChange} defaultValue={formValues.message}></textarea>
                                                                </span>
                                                            </label>
                                                            <p className='text-danger'><strong>{ formErrors.message }</strong></p>
                                                        </div>

                                                        <div className="offset-md-6 col-lg-6">
                                                            <button className="submit prt-btn prt-btn-size-md prt-btn-shape-rounded prt-btn-style-fill prt-btn-color-skincolor w-100" type="submit">SUBMIT NOW</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  

        <section className="prt-row bg-base-skin fid-section mt_240 mb_10 res-991-m-0 clearfix">
            <div className="container">
                <div className="row no-gutters ml-20">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                    
                        <div className="prt-fid inside style1 res-991-pb-100">
                            <div className="prt-fid-contents text-base-white">
                                <h4 className="prt-fid-inner">
                                    <span   data-appear-animation="animateDigits" 
                                            data-from="0" 
                                            data-to="1" 
                                            data-interval="1" 
                                            data-before="" 
                                            data-before-style="sup" 
                                            data-after="+"
                                            data-after-style="sub" 
                                            className="numinate">1</span>
                                </h4>
                                <span className="num">1</span>
                                <h3 className="prt-fid-title">Years Of <br/> Experience </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        
                        <div className="prt-fid inside style1 res-991-pb-100">
                            <div className="prt-fid-contents text-base-white">
                                <h4 className="prt-fid-inner">
                                    <span   data-appear-animation="animateDigits" 
                                            data-from="0" 
                                            data-to="20" 
                                            data-interval="1" 
                                            data-before="" 
                                            data-before-style="sup" 
                                            data-after="+"
                                            data-after-style="sub" 
                                            className="numinate">20</span>
                                </h4>
                                <span className="num">20</span>
                                <h3 className="prt-fid-title">Charging <br/> Points </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        
                        <div className="prt-fid inside style1 res-575-pb-100">
                            <div className="prt-fid-contents text-base-white">
                                <h4 className="prt-fid-inner">
                                    <span   data-appear-animation="animateDigits" 
                                            data-from="0" 
                                            data-to="9" 
                                            data-interval="1" 
                                            data-before="" 
                                            data-before-style="sup" 
                                            data-after="+"
                                            data-after-style="sub" 
                                            className="numinate">1</span><span>k</span>
                                </h4>
                                <span className="num">1k</span>
                                <h3 className="prt-fid-title"> Clients <br/> Review </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        
                        <div className="prt-fid inside style1">
                            <div className="prt-fid-contents text-base-white">
                                <h4 className="prt-fid-inner">
                                    <span   data-appear-animation="animateDigits" 
                                            data-from="0" 
                                            data-to="95" 
                                            data-interval="10" 
                                            data-before="" 
                                            data-before-style="sup" 
                                            data-after="+"
                                            data-after-style="sub" 
                                            className="numinate">95</span>
                                </h4>
                                <span className="num">95</span>
                                <h3 className="prt-fid-title"> Happy <br/> Members </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        

    </div>
  )
}

export default HomeSection