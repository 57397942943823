import {React, useState, useEffect, useRef} from 'react'
import {useParams} from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import productImg from '../../../images/products/product.jpeg';
import axios from 'axios';
import $ from 'jquery';

import productData from '../ProductData.json';
// import FormModal from '../../includes/FormModal.js'

const notify = () => toast.success("Congratulation, Your product placed successfully");

function ProductDetails() {
  const params = useParams();
  const imgFluid2 = {
      width: "50%",
  };

  const productQnty = {
      height: "45px",
      width: "75px"
  }
  var id = params.id;
  var data = productData[id];
  let firstImg = "../assets/images/products/"+data.images[0];
  console.log('all data: ',data )
  const initialValue = { productUsername: "", productEmail: "", productNumber: "", productMessage: ""};
  const [formValues, setFormValues] = useState(initialValue);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
      const {name, value} = e.target;
      setFormValues({ ...formValues, [name]: value });
  }

  const productFormRef = useRef(null);

  let productDetailList = productData[id].content;

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const handleSubmit = (e) => {
      e.preventDefault();
      setFormErrors(validate(formValues));
      setIsSubmit(true);
  }

  // const productQuantity = (e) => {
  //   console.log(e.target.value);
  // }

  useEffect(() => {
      if(Object.keys(formErrors).length === 0 && isSubmit) {
          setFormValues({ ...formValues, ['quantity']: 5 });
          console.log('Kuldeep: ',formValues)
          let productQuantity = document.getElementById('product_quantity');
          // console.log('qnty', productQuantity.value, data.product_type);
          
          let url = "https://api.cnkbharat.com/api/product-mail";
          axios.post(url, {
            name: document.getElementById('productUsername').value,
            email: document.getElementById('productEmail').value,
            mobile: document.getElementById('productNumber').value,
            message: document.getElementById('productMessage').value,
            product_name: data.product_type,
            quantity: productQuantity.value,
        })
        .then(() => {
            // productFormRef.current.reset();
            handleClose();
            // setTimeout(function() {
              $('#productUsername').val('');
              $('#productEmail').val('');
              $('#productNumber').val('');
              $('#productMessage').val('');
            // }, 1000);
            setFormValues(initialValue);
            notify();
        });
      }
  }, [formErrors])

  const handleShow = () => setShowModal(true);

  const validate = (values) => {
      const errors = {};
      // console.log('check Values: ', values)
      if(!values.productUsername) {
          errors.productUsername = "Name is required";
      }
      if(!values.productEmail) {
          errors.productEmail = "Email is required";
      }
      if(!values.productNumber) {
          errors.productNumber = "Number is required";
      }
      if(!values.productMessage) {
          errors.productMessage = "Message is required";
      }

      return errors
  }
  
  return (
      <div>
        <div className="site-main">
          {/*prt-row*/}
          <div className="prt-row sidebar prt-sidebar-right bg-base-grey clearfix">
            <div className="container">
              {/* row */}
              <div className="row">
                <div className="col-lg-8 content-area m-auto">
                  <article className="prt-pf-single-content-wrapper box-shadow pt-50 pb-35 pl-40 pr-40 clearfix res-575-pl-15 res-575-pr-15 bg-base-white">
                    {/* post-featured-wrapper */}
                    <div className="prt-pf-featured-wrapper prt-featured-wrapper">
                      <div className="prt-post-featured">
                        <img className="border-rad_6" src={firstImg} alt="" style={imgFluid2} />
                      </div>
                    </div>{/* post-featured-wrapper end */}
                    <div className="entry-content">
                      <div className="row mb-30">
                        <div className="col-xl-6 col-md-12">
                          <div className="prt_single_image-wrapper border-rad_5 overflow-hidden mt-30 mb-15">
                            <img className="img-fluid" src={firstImg} alt="single-11" />
                          </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                          <div className="prt-pf-single-detail-box">
                            <h3 className="prt-pf-detailbox-title">Product Details:</h3>
                            <ul className="prt-pf-detailbox-list">
                              <li>
                                <span>Product type : </span>
                                <span className="prt-pf-right-details">{data.product_type}</span>
                              </li>
                              <li>
                                <span>Product Quantity : </span>
                                <span className="prt-pf-right-details"><input type="number" id="product_quantity" name="product_quantity" defaultValue="1" style={productQnty} pattern="[0-9]*" /></span>
                              </li>
                            </ul>
                            <div className="prt-single-pf-footer">
                              <div className="prt-social-share-wrapper">
                                <div className="prt-social-share-links">
                                  <button type="button" className="btn btn-md btn-success" data-toggle="modal" data-target="#formModal" onClick={handleShow}>Buy Now</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h3>Detail Product Information</h3>
                      {/* <p>We’ve been established as a family furnishers business for over 60 years. With a beautiful showroom in beautiful city Kolkata, out friendly staff and pride themselves in roject Quality Management and providing a high level of service and safety.</p>
                      <p>Connectors and combines the flexibility and reliability of ndustry-standards connections with the convenience of automation. As it works with any charger or vehicle.</p> */}
                      <center><div className="col-xl-6 col-md-12">
                        <ul className="list-group">
                          {productDetailList.map((item, index) => (
                            <li key={index} className="list-group-item">
                                {item}
                            </li>
                          ))}
                        </ul>
                      </div></center>
                      <div className="row mb-10">
                        
                        
                      </div> 
                      
                      <div className="row">
                        
                      </div>
                    </div>
                  </article>
                </div>
              </div>{/* row end */}
            </div>
          </div>
          {/*prt-row end*/}
        </div>
        
        
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Products</Modal.Title>
            </Modal.Header>
            
            <form ref={productFormRef} onSubmit={ handleSubmit } id="add_product_form">
                <Modal.Body>
                    <div className="form-group row">
                        <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Your Name</label>
                        <div className="col-sm-6">
                            <input name="productUsername" id="productUsername" type="text" defaultValue= {formValues.productUsername} placeholder="Your Name*" onChange={handleChange} />
                            <p className='text-danger'><strong>{ formErrors.productUsername }</strong></p>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Email Address*</label>
                        <div className="col-sm-6">
                            <input name="productEmail" id="productEmail" type="text" defaultValue={formValues.productEmail} placeholder="Email Address*" onChange={handleChange} />
                            <p className='text-danger'><strong>{ formErrors.productEmail }</strong></p>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Phone Number*</label>
                        <div className="col-sm-6">
                            <input name="productNumber" id="productNumber" type="text" defaultValue={formValues.productNumber} onChange={handleChange} placeholder="Phone Number" />  
                            <p className='text-danger'><strong>{ formErrors.productNumber }</strong></p>
                        </div>
                    </div>
                    
                    <div className="form-group row">
                        <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Message*</label>
                        <div className="col-sm-6">
                            <textarea name="productMessage" id="productMessage" cols="40" rows="5" placeholder="Your Message" aria-required="true" onChange={handleChange} defaultValue={formValues.productMessage}></textarea>
                            <p className='text-danger'><strong>{ formErrors.productMessage }</strong></p> 
                        </div>
                    </div>  
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                    <Button variant="primary" onClick={ handleSubmit }> Buy </Button>
                </Modal.Footer>
            </form>
        </Modal>
        <ToastContainer />
      </div>
  )
}

export default ProductDetails