import {React, useState} from 'react';
// import image from '../../images/team.jpg'
import { Modal } from 'react-bootstrap';
import prasant from './images/prashant.jpeg'
import noImage from './images/noimage.jpg'
import nandan from './images/nandan.jpeg'
import partho from './images/partho.jpeg'
import { CiMail } from "react-icons/ci";
// import $ from 'jquery';

function OurTeams() {
    const mystyle = {
        width: "80%",
        backgroundColor: "white",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        marginBottom: "25px"
    };
    const h3Style = {
        fontSize: "18px",
        cursor: "pointer"
    }

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const [teamTitle, setTeamTitle] = useState('');
    const [teamDescription, setTeamDescription] = useState('');
    const handleShow = (name, position, describe) => {
        setShowModal(true);
        setTeamTitle( name+'('+position+')' );
        setTeamDescription( name+' '+describe );
        // $('#modal_title').html(name+'('+position+')');
        // $('#modal_full_description').html(describe);
    };
  return (
    <div>
        <div className='container' style={{padding: "100px"}}>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-12"><center><h3>Our Teams</h3></center></div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="featured-imagebox featured-imagebox-team style1" style={mystyle}>
                        <div className="prt-box-view-overlay">
                            <div className="featured-thumbnail">
                                <img className="img-fluid" src={noImage} alt="image" /> 
                            </div>
                        </div>
                        <div className="featured-content featured-content-team">
                            <div className="featured-title">
                                <h3 style={h3Style}><span onClick={() => handleShow("Mr. Chandra Shekhar Singh","Director","is a Businessman who started manufacturing and processing unit of Hydraulic Cylinders for various applications in the year 1984 in Kolkata. Mr. Chandra Shekhar Singh has a Bachelor of Commerce from the University of Calcutta (now Kolkata). Mr. Singh brings more than 38 years of business experience along with strong knowledge of Marketing and Finance")}>Mr. Chandra Shekhar Singh</span></h3>
                            </div>
                            <div className="team-position">Director (Businessman)</div>
                            <div className="team-icon pt-25">
                                <CiMail />
                                <a href="mailto:chandrashekhar@cnkbharat.com">chandrashekhar@cnkbharat.com</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="featured-imagebox featured-imagebox-team style1" style={mystyle}>
                        <div className="prt-box-view-overlay">
                            <div className="featured-thumbnail">
                                <img className="img-fluid" src={nandan} alt="image" /> 
                            </div>
                        </div>
                        <div className="featured-content featured-content-team">
                            <div className="featured-title">
                                <h3 style={h3Style}><span onClick={() => handleShow("Mr. Nandan Bardhan", "Director", "is a Mechanical Engineer who was in UT Limited a Hydraulic Cylinder manufacturing company of International repute (previously known as Usha Telehoist Limited which had a collaboration with Telehoist, UK). He headed both their Kolkata (WB) and Hosur (TN) Plant as Director (Operations) and later in 2015 retired as the Executive Director of the company Mr. Bardhan brings more than 42 years of professional experience as a senior executive and senior engineer of an international manufacturing company along with a knowledge of Electrical Vehicle Charger Manufacturing to C&N")}>Mr. Nandan Bardhan</span></h3>
                            </div>
                            <div className="team-position">Director (Mechanical Engineer)</div>
                            <div className="team-icon pt-25">
                                <CiMail />
                                <a href="mailto:nandan.cnkbharat.com">nandan@cnkbharat.com</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="featured-imagebox featured-imagebox-team style1" style={mystyle}>
                        <div className="prt-box-view-overlay">
                            <div className="featured-thumbnail">
                                <img className="img-fluid" src={prasant} alt="image" /> 
                            </div>
                        </div>
                        <div className="featured-content featured-content-team">
                            <div className="featured-title">
                                <h3 style={h3Style}><span onClick={() => handleShow("Mr. Prashant Singh","CEO", "is a Mechanical Engineer and started his carrier as Design Engineer in C. S. Industries from 2016 till 2018. Mr. Prashant Singh later in 2019 joined Holdwell Components as Quality Engineer manufacturers of EPDM Gaskets used for joining DI Pipes. Mr Prashant played a pivotal role in ensuring quality components. Presently Mr. Prashant is the CEO of C&N Green Energy Pvt. Ltd., with a background in Quality. Mr. Prashant whose portfolio includes complete Operation in producing quality electric Vehicle Chargers")}>Mr. Prashant Singh</span></h3>
                            </div>
                            <div className="team-position">CEO (Mechanical Engineer)</div>
                            <div className="team-icon pt-25">
                                <CiMail />
                                <a href="mailto:prasahant@cnkbharat.com">prashant@cnkbharat.com</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="offset-md-3 offset-lg-4 col-lg-4 col-md-6 col-12">
                    <div className="featured-imagebox featured-imagebox-team style1" style={mystyle}>
                        <div className="prt-box-view-overlay">
                            <div className="featured-thumbnail">
                                <img className="img-fluid" src={partho} alt="image" /> 
                            </div>
                        </div>
                        <div className="featured-content featured-content-team">
                            <div className="featured-title">
                                <h3 style={h3Style}><span onClick={() => handleShow("Mr. Partha Bhattacharyya", "Head Production", "is an Electrical Engineer having vast experience as Maintenance Expert, Production & Project Engineering He worked in different capacities since 1986 and having vast knowledge in Electrical, Mechanical & Electronics. Mr. Partha brings along with him 36 years experience")}>Mr. Partha Bhattacharyya</span></h3>
                            </div>
                            <div className="team-position">Head Production (Electrical Engineer)</div>
                            <div className="team-icon pt-25">
                                <CiMail />
                                <a href="mailto:info@gmail.com">Partha@cnkbharat.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span id="modal_title">{teamTitle}</span>
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                <div id="modal_full_description">{teamDescription}</div>  
            </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}> Close </Button>
            </Modal.Footer> */}
        </Modal> 
    </div>
  )
}

export default OurTeams