import React from 'react'
import service1 from '../../images/services/services-02-844x500.jpg';
import service2 from '../../images/services/servives-02.jpg';
import service3 from '../../images/services/servives-03.jpg';
import service4 from '../../images/services/services-03-407x222.jpg';
import gallery1 from '../../images/services/gallery-1.jpeg';
import gallery2 from '../../images/services/gallery-2.jpeg';
import gallery3 from '../../images/services/gallery-3.jpeg';
import gallery4 from '../../images/services/gallery-4.jpeg';
import gallery5 from '../../images/services/gallery-5.jpeg';
import gallery6 from '../../images/services/gallery-6.jpeg';
// import file1 from '../asset/pdf/CEDAR_7K5_AC.pdf';
// import file2 from '../../images/services/services-03-407x222.jpg';
function ServiceSection() {
  return (
    
    <div className="site-main">

        <section className="prt-row clearfix">
            <div className="prt-team-member-single-content-wrapper prt-team-member-view-default">
                <div className="container">
                    <div className=" row prt-team-member-single-content">
                        <div className="col-lg-4 widget-area sidebar-right pl-15 pr-15 ml_10 res-991-mt-50 res-991-pl-0">
                            <aside className="widget widget-nav-menu with-title">
                                <h3 className="widget-title">Main Services</h3>
                                <ul>
                                    <li><a href="charge-Point-Services.html"> Installation </a></li>
                                    <li><a href="battery-maintenance.html"> After Sales Services </a></li>
                                    {/* <li><a href="digital-services.html"> Digital Services </a></li>
                                    <li><a href="hybrid-car-watches.html"> Hybrid Car Watches </a></li>
                                    <li><a href="drivers-services.html"> Drivers Services </a></li>
                                    <li><a href="home-charging.html"> Home Charging </a></li> */}
                                </ul>
                            </aside> 
                            <aside className="widget widget-download with-title">
                                <h3 className="widget-title">Download</h3>
                                <ul className="download">
                                    <li><i className="fa fa-file-pdf-o"></i><a href="../assets/pdf/CEDAR_7K5_AC.pdf" target="_blank">Cedar </a>
                                    <p>[350KB]</p></li>
                                    <li><i className="fa fa-file-pdf-o"></i><a href="../assets/pdf/MAPLE_22KW_AC.pdf">Maple</a>
                                    <p>[250KB]</p></li>
                                    <li><i className="fa fa-file-pdf-o"></i><a href="../assets/pdf/OAK_4K6_AC.pdf">Oak</a>
                                    <p>[350KB]</p></li>
                                    <li><i className="fa fa-file-pdf-o"></i><a href="../assets/pdf/PINUS_3K3_AC.pdf">Pinus</a>
                                    <p>[250KB]</p></li>
                                </ul>
                            </aside>
                            <aside className="widget widget-archive with-title-main">
                                <h3 className="widget-title">Gallery</h3>
                                <div id="gallery-2" className="gallery-wrapper">
                                    <figure className="gallery-item">
                                        <div className="gallery-icon landscape">
                                            <a className="prt_prettyphoto" href={gallery1} rel="prettyPhoto[coregallery]" data-rel="prettyPhoto">
                                            <img width="120" height="120" className="img-fluid" src={gallery1} alt="gellary_img" /></a>
                                        </div>
                                    </figure>
                                    <figure className="gallery-item">
                                        <div className="gallery-icon landscape">
                                            <a className="prt_prettyphoto" href={gallery2} rel="prettyPhoto[coregallery]" data-rel="prettyPhoto">
                                            <img width="120" height="120" className="img-fluid" src={gallery2} alt="gellary_img" /></a>
                                        </div>
                                    </figure>
                                    <figure className="gallery-item">
                                        <div className="gallery-icon landscape">
                                            <a className="prt_prettyphoto" href={gallery3} rel="prettyPhoto[coregallery]" data-rel="prettyPhoto"><img width="120" height="120" className="img-fluid" src={gallery3} alt="gellary_img" /></a>
                                        </div>
                                    </figure>
                                    <figure className="gallery-item">
                                        <div className="gallery-icon landscape">
                                            <a className="prt_prettyphoto" href={gallery4} rel="prettyPhoto[coregallery]" data-rel="prettyPhoto"><img width="120" height="120" className="img-fluid" src={gallery4} alt="gellary_img" /></a>
                                        </div>
                                    </figure>
                                    <figure className="gallery-item">
                                        <div className="gallery-icon landscape">
                                            <a className="prt_prettyphoto" href={gallery5} rel="prettyPhoto[coregallery]" data-rel="prettyPhoto"><img width="120" height="120" className="img-fluid" src={gallery5} alt="gellary_img" /></a>
                                        </div>
                                    </figure>
                                    <figure className="gallery-item">
                                        <div className="gallery-icon landscape">
                                            <a className="prt_prettyphoto" href={gallery6} rel="prettyPhoto[coregallery]" data-rel="prettyPhoto"><img width="120" height="120" className="img-fluid" src={gallery6} alt="gellary_img" /></a>
                                        </div>
                                    </figure>
                                </div>
                            </aside>
                            {/* <aside className="widget widget-banner-main with-title">
                                <h3 className="widget-title">Subscribe Us</h3>
                                <div className="prt-col-bgcolor-yes text-base-white col-bg-img-five prt-col-bgimage-yes prt-bg mt-25 p-40 res-991-p-30 res-991-pb-5">
                                    <div className="prt-col-wrapper-bg-layer prt-bg-layer">
                                        <div className="prt-col-wrapper-bg-layer-inner"></div>
                                    </div>
                                    <div className="layer-content">
                                        <p>Subscribe us & get latest news & articles to inbox.</p>
                                        <form className="mc4wp-form mc4wp-form-24" method="post" data-id="24" data-name="Newsletter Form">
                                            <div className="mc4wp-form-fields">
                                                <div className="mailchimp-inputbox">
                                                    <input type="email" name="EMAIL" placeholder="Your email address.." required="" />
                                                </div>
                                            </div>
                                            <button className="submit prt-btn prt-btn-size-md prt-btn-shape-rounded prt-btn-style-fill prt-icon-btn-right prt-btn-color-skincolor mt-20 res-991-mb-30 w-100 border-0" type="submit">Subscribe</button>
                                        </form>
                                    </div>
                                </div>
                            </aside> */}
                        </div>
                        <div className="col-lg-8">
                            <div className="bg-base-white box-shadow pt-50 pb-50 pl-45 pr-40 mr_10 res-575-pr-15 res-575-pl-15 res-991-mr-0">
                                <div className="prt-service-description">
                                    <div className="pb-20">
                                        <div className="prt_fatured_image-wrapper">
                                            <img className="img-fluid border-rad_6" src={service1} alt="services-1" />
                                        </div>
                                    </div>
                                    <div className="pt-30">
                                        <h3>Wall Mounted AC Fast Chargers</h3>
                                        <p>Our Wall Mounted AC Fast Chargers can be installed Both Indoors &amp; Outdoors. They can be only accessed through their respective Keys, RFID Cards &amp; Apps making them completely Safe, Secure &amp; User Friendly. </p>
                                        {/* <p>Brings plenty of the benefit & it to definitely incurs significant costs.Faster transaction, encryption for security, quicker access to fund</p> */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="featured-imagebox featured-imagebox-services style3">
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3><a href="drivers-services.html">Installation</a></h3>
                                                </div>
                                                <div className="featured-desc">
                                                    <p>Our Service Engineers will install the Chargers Outdoors/Indoors as per customer requirements.</p>
                                                </div>
                                                <a className="prt-icon bottom_icon" href="drivers-services.html">
                                                    <i className="flaticon-right-arrow-2"></i>
                                                </a>
                                            </div>
                                            <div className="prt-box-view-overlay border-rad_6">
                                                <div className="featured-thumbnail">
                                                    <img className="img-fluid" src={service2} alt="image" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="featured-imagebox featured-imagebox-services style3">
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3><a href="home-charging.html">After Sales Services</a></h3>
                                                </div>
                                                <div className="featured-desc">
                                                    <p>We Have a Team Of Well Trained Engineers to take care of After Sales Services of All <span className="logo_txt_primary">cnk</span><span className="logo_txt_secondary">bharat</span> brand EV Chargers.</p>
                                                </div>
                                                <a className="prt-icon bottom_icon" href="drivers-services.html">
                                                    <i className="flaticon-right-arrow-2"></i>
                                                </a>
                                            </div>
                                            <div className="prt-box-view-overlay border-rad_6">
                                                <div className="featured-thumbnail">
                                                    <img className="img-fluid" src={service3} alt="image" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-30 pb-20">
                                    <h3>Partner With Us</h3>
                                    <p>We invite all Enthusiastic Entrepreneurs to join the EV Revolution by Partnering with us.</p>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 col-md-12">
                                        <div className="prt-featured-wrapper res-767-pr-0"> 
                                            <div className="featured-thumbnail">
                                                <img className="img-fluid" src={service4} alt="image" />
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-md-12">
                                        <ul className="prt-list prt-list-style-icon d-grid prt-list-icon-color-skincolor res-1199-mt-30">
                                            <li><i className="fa fa-check-square"></i><span className="prt-list-li-content">Provide Personal &amp; Public Chargers.</span></li>
                                            <li><i className="fa fa-check-square"></i><span className="prt-list-li-content">Increase Number of Charging Points.</span></li>
                                            <li><i className="fa fa-check-square"></i><span className="prt-list-li-content">Boost the EVSE Infrastructure.</span></li>
                                            <li><i className="fa fa-check-square"></i><span className="prt-list-li-content">Reduce Demand Supply Mismatch of EVs &amp; Chargers</span></li>
                                            <li><i className="fa fa-check-square"></i><span className="prt-list-li-content">Chargers are Compact, Noiseless &amp; User Friendly</span></li>
                                            <li><i className="fa fa-check-square"></i><span className="prt-list-li-content">Access control put an electric vehicle.</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="pt-40">
                                    <h3>Certified Electric Vehicle Chargers</h3>
                                    <p className="mb-0">All our Chargers are ISO Standards Compliant &amp; Certified under ISO 9001:2015</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ServiceSection