import React from 'react';

function PrivacyPolicySection() {
    
  return (
        
        <div className="site-main">

            <section className="prt-row about-section clearfix">
                <div className="container" style={{marginTop: '100px'}}>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="res-1400-pl-15 res-1199-ml-30 res-991-pt-30 pl-15 pt-25 res-480-ml-0 res-480-pl-0">
                                
                                <div className="section-title">
                                    <div className="">
                                        <h3 style={{fontSize: "20px"}}>Privacy Policy</h3>
                                        {/* <h2 className="title">Our mission is to put an electric vehicle charge</h2> */}
                                    </div>
                                    <div className="title-desc">
                                        <p style={{fontSize: "16px"}}>This Privacy Policy describes how  C&N Green Energy  (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) collects, uses, and discloses information that we may collect from you when you visit <strong><a href='https://cnkbharat.com/' target='_blank' rel="noreferrer">www.cnkbharat.com</a></strong> or when you otherwise interact with us. By accessing or using the Website, you agree to the terms of this Privacy Policy.</p>
                                        
                                        <h4>Information We Collect</h4>
                                        <ul>
                                            <li><strong>Information You Provide:</strong> We may collect personal information that you voluntarily provide to us when you interact with the Website, such as when you register for an account, subscribe to our newsletter, make a purchase, or contact us for support. This information may include your name, email address, mailing address, phone number, and payment information.</li>
                                            <li><strong>Automatically Collected Information:</strong> When you visit the Website, we may automatically collect certain information about your device and usage of the Website, including your IP address, browser type, operating system, referral URLs, and other similar information.</li>
                                            <li><strong>Cookies:</strong> We may use cookies and similar tracking technologies to collect information about your interactions with the Website. Cookies are small text files that are stored on your device to help us recognize you and improve your experience on the Website. You can disable cookies in your browser settings, but please note that some features of the Website may not function properly if cookies are disabled.</li>
                                        </ul>

                                        <h4>How We Use Your Information</h4>
                                        <ul>
                                            <li>We may use the information we collect from you for various purposes, including to:</li>
                                            <li>Provide and maintain the Website.</li>
                                            <ul>
                                                <li>Process transactions and fulfill orders.</li>
                                                <li>Communicate with you, including responding to your inquiries and providing customer support.</li>
                                                <li>Send you promotional emails and newsletters, if you have opted in to receive them.</li>
                                                <li>Personalize your experience on the Website.</li>
                                                <li>Analyze usage of the Website and improve our products and services; and</li>
                                                <li>Comply with legal and regulatory requirements.</li>
                                            </ul>
                                            <li>We may share your information with third parties in the following circumstances:</li>
                                            <li>With service providers who assist us in operating the Website or providing our products and services.</li>
                                            <li>With third-party vendors, consultants, and other service providers who need access to such information to perform work on our behalf.</li>
                                            <li>In response to a subpoena, court order, or other legal request.</li>
                                            <li>If we believe that disclosure is necessary or appropriate to protect our rights, property, or safety, or the rights, property, or safety of our users or others or.</li>
                                            <li>With your consent or at your direction.</li>
                                        </ul>

                                        <h4>Data Security</h4>
                                        <ul>
                                            <li>We take reasonable measures to protect the security of your information, both during transmission and once it is received. However, no method of transmission over the internet or method of electronic storage is completely secure, so we cannot guarantee absolute security.</li>
                                        </ul>

                                        <h4>Children&#39;s Privacy</h4>
                                        <ul>
                                            <li>The Website is not directed to children under the age from 13 to 18, and we do not knowingly collect personal information from children under the age from 13 to 18. If we learn that we have collected personal information from a child under the age from 13 to 18, we will take appropriate steps to delete such information.</li>
                                        </ul>

                                        <h4>Changes to this Privacy Policy</h4>
                                        <ul>
                                            <li>We reserve the right to modify this Privacy Policy at any time, so please review it frequently. If we make material changes to this Privacy Policy, we will notify you by posting the updated Privacy Policy on the Website.</li>
                                        </ul>

                                        <h4>Contact Us</h4>
                                        <ul>
                                            <li>If you have any questions about this Privacy Policy, please contact us at <strong>cngepl@cnkbharat.com</strong>.</li>
                                        </ul>

                                        <p style={{fontSize: "16px"}}>By accessing or using the Website, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            

        </div>
  )
}

export default PrivacyPolicySection