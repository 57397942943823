import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import whatsappImg from './images/whatsapp.png';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <div className='fixed-bottom' style={{ bottom: '10px', left: '10px' }}>
      <a href='https://wa.me/9051257814?text=Hii, How can I help you!' target='_blank' rel="noreferrer">
        <img src={whatsappImg} width='60' />
      </a>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
