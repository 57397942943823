import React from 'react'
import Header from '../includes/Header'
import BlogHeader from './BlogHeader'
import BlogSection from './BlogSection'
import Footer from '../includes/Footer'
function Blog() {
  return (
    <div>
        <div className="page">
            <Header />
            <BlogHeader />
            <BlogSection />
            <Footer />
            
            <a id="totop" href="#top">
                <i className="fa fa-angle-up"></i>
            </a>

        </div>
    </div>
  )
}

export default Blog