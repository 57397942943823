import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Banner1 from '../../banner-images/slider-mainbg-001.png';
import Banner2 from '../../banner-images/slider-mainbg-002.png';

function Slider() {

  return (
    <div>        
    <Carousel>
      <Carousel.Item>
        <img className="d-block w-100" src={Banner2} alt="First slide" style={{height: "680px"}} />
        
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={Banner1} alt="Second slide" style={{height: "680px"}} />
       
      </Carousel.Item>
    </Carousel>
        </div>

  )
}

export default Slider