import React from 'react'

function ServiceHeaderBanner() {
  return (
        <div className="prt-page-title-row-1">
            <div className="prt-page-title-row-inner-1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="page-title-heading">
                                <h2 className="title">Service</h2>
                            </div>
                            <div className="title-desc">
                                <p>It’s time for a new generation of personal fast transportation. We’re reinventing the bright future</p>
                            </div>
                            <div className="breadcrumb-wrapper-main">
                                <div className="container">
                                    <div className="breadcrumb-wrapper-inner">
                                        <span>
                                            <a href="index.html" className="home">&nbsp;&nbsp;Home</a>
                                        </span>
                                        <span className="prt-bread-sep">&nbsp; - &nbsp;</span>
                                        <span>Service</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                    
        </div>
  )
}

export default ServiceHeaderBanner