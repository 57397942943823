import React from 'react'
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import PrivacyPolicySection from './PrivacyPolicySection';

function PrivacyPolicy() {
  return (
    <div>
        <div className="page">
            <Header />
            <PrivacyPolicySection />
            <Footer />
            
            <a id="totop" href="#top">
                <i className="fa fa-angle-up"></i>
            </a>

        </div>
    </div>
  )
}

export default PrivacyPolicy