import React from 'react';
import {useParams} from 'react-router-dom';
import blogData from '../blogData.json';

function Details() {
    const params = useParams();
    let blogSpecificDetail = '';
    let blogSpecificData = blogData.blogs[params.id];
    let imgPath = '../assets/images/blog1/blog'+(parseInt(params.id)+parseInt(1))+'.png';
    // console.log('blog', params.id, blogData.blogs[1])
    console.log('img', imgPath)
    if(blogSpecificData) {
        blogSpecificDetail = <div className="site-main">
        
        <div className="prt-row sidebar prt-sidebar-right bg-base-grey clearfix">
        <div className="container">
            
            <div className="row">
            <div className="col-lg-8 content-area m-auto">
                <article className="post prt-blog-single bg-base-white box-shadow pt-50 pb-50 pl-40 pr-40 clearfix res-575-pl-15 res-575-pr-15">
                
                <div className="prt-post-featured-wrapper prt-featured-wrapper">
                    <div className="prt-post-featured">
                    <img className="img-fluid" src={imgPath} alt="" />
                    </div>
                </div>
                <div className="prt-blog-single-content">
                    <div className="prt-post-entry-header">
                    <div className="post-meta">
                        <span className="prt-meta-line byline">
                        <img src={imgPath} className="img-fluid" alt="" /><strong className="text-base-dark">By : </strong>Justin N. Thomas</span>
                        <span className="prt-meta-line tags"><i className="ti ti-comment-alt" />Comments : 02</span>
                        <span className="prt-meta-line comment-links"><i className="flaticon-calendar" />09 Sep, 2022</span>
                    </div>
                    <header className="entry-header">
                        <h2 className="entry-title">{blogSpecificData.heading}</h2>
                    </header>
                    </div>
                </div>
                <div className="entry-content">
                    <div className="prt-box-desc-text">{blogSpecificData.content}</div>
                </div>
                </article>
            </div>
            </div>
        </div>
        </div>
        
    </div>
    }
    return (
    <div>
        {blogSpecificDetail}        
    </div>
    )
}

export default Details