import React from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import ServiceHeaderBanner from './ServiceHeaderBanner'
import ServiceSection from './ServiceSection'

function Service() {
  return (
        <div>
            <div className='page' >
            
                <Header />
                <ServiceHeaderBanner />
                <ServiceSection />
                <Footer />
                
                <a id="totop" href="#top">
                    <i className="fa fa-angle-up"></i>
                </a>
            </div>
      </div>
  )
}

export default Service