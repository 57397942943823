import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";
import ScrollToTopOnRedirect from './ScrollToTopOnRedirect';
import Home from './pages/home/Home';
import Aboutus from './pages/aboutus/Aboutus';
import Services from './pages/services/Service';
import Contactus from './pages/contactus/Contactus'
import Blog from './pages/blogs/Blog';
import Product from './pages/products/Product';
import BlogDetail from './pages/blogs/blogDetails/BlogDetail';
import ProductDetailLayout from './pages/products/details/ProductDetailLayout';
import TermCondition from './pages/terms-condition/TermCondition';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import RefundsCancellation from './pages/refunds-cancellation/RefundsCancellation';
import Pricing from './pages/pricing/Pricing';

function Web() {
  return (
    <div className="App">
        <BrowserRouter>
            <ScrollToTopOnRedirect />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/about" element={<Aboutus />} />
                <Route exact path="/services" element={<Services />} />
                <Route exact path="/blog" element={<Blog />} />
                <Route exact path="/contact" element={<Contactus />} />  
                <Route exact path="/products" element={<Product />} />  
                <Route exact path="/:id" element={<BlogDetail />} />  
                <Route exact path="/products/:id" element={<ProductDetailLayout />} />  
                <Route exact path="/term-condition" element={<TermCondition />} />  
                <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />  
                <Route exact path="/refunds-cancellation" element={<RefundsCancellation />} />  
                <Route exact path="/pricing" element={<Pricing />} />  
            </Routes>
        </BrowserRouter>
    </div>
  )
}

export default Web