import React from 'react';

function RefundsCancellationSection() {
    
  return (
        
        <div className="site-main">

            <section className="prt-row about-section clearfix">
                <div className="container" style={{marginTop: '100px'}}>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="res-1400-pl-15 res-1199-ml-30 res-991-pt-30 pl-15 pt-25 res-480-ml-0 res-480-pl-0">
                                
                                <div className="section-title">
                                    <div className="">
                                        <h3 style={{fontSize: "20px"}}>Refunds/Cancellation Polity</h3>
                                    </div>
                                    <div className="title-desc">
                                        <p style={{fontSize: "16px"}}>This Refunds/Cancellation Policy governs the terms under which refunds and cancellations are processed for purchases made on  <strong><a href='https://cnkbharat.com/' target='_blank' rel="noreferrer">www.cnkbharat.com</a></strong> (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;). By making a purchase on the website, you agree to the terms of this policy.</p>
                                        
                                        <h4>Refunds</h4>
                                        <ul>
                                            <li>Digital Products: Due to the nature of digital products, all sales are final and non-refundable unless otherwise specified at the time of purchase or required by law.</li>
                                            <li>Physical Products: If you are not satisfied with your purchase of a physical product, you may be eligible for a refund or exchange within 7 - 10 working days from date of delivery. To be eligible for a refund or exchange, the product must be unused, in its original packaging, and in the same condition as when you received it. You may be responsible for return shipping costs.</li>
                                            <li>Services: Refunds for services may be issued on a case-by-case basis, depending on the nature of the service and any applicable terms or agreements. Please contact us for more information about refunds for services.</li>
                                        </ul>

                                        <h4>Cancellations</h4>
                                        <ul>
                                            <li>Orders: You may cancel an order for a physical product before it has been shipped. Once an order has been shipped, it cannot be canceled, and the refund policy described above will apply.</li>
                                            <li>Subscriptions: You may cancel a subscription at any time by accessing your account settings on the website or by contacting us directly. Cancellations will take effect at the end of the current billing period, and you will not be charged for any subsequent billing cycles.</li>
                                        </ul>

                                        <h4>Refund Process</h4>
                                        <ul>
                                            <li>To request a refund or cancellation, please contact us at <strong>cngepl@cnkbharat.com</strong> and provide your order details and reason for the refund or cancellation request.</li>
                                            <li>Refunds will be issued to the original payment method used for the purchase, unless otherwise specified by us.</li>
                                            <li>Refunds may take 7-10 working days to process, depending on your payment provider.</li>
                                        </ul>

                                        <h4>Exceptions</h4>
                                        <ul>
                                            <li>Certain products or services may be exempt from the refund and cancellation policy described above. Any exceptions will be clearly stated at the time of purchase.</li>
                                        </ul>

                                        <h4>Changes to this Policy</h4>
                                        <ul>
                                            <li>We reserve the right to modify this Refunds/Cancellation Policy at any time, so please review it frequently. If we make material changes to this policy, we will notify you by posting the updated policy on the website.</li>
                                        </ul>

                                        <h4>Contact Us</h4>
                                        <ul>
                                            <li>If you have any questions about this Refunds/Cancellation Policy, please contact us at <strong>cngepl@cnkbharat.com</strong>.</li>
                                        </ul>

                                        
                                        <p>By making a purchase on the website, you acknowledge that you have read, understood, and agree to the terms of this Refunds/Cancellation Policy.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            

        </div>
  )
}

export default RefundsCancellationSection