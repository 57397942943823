import React from 'react'
import Header from '../includes/Header'
import ProductHeaderBanner from './ProductHeaderBanner'
import ProductSection from './ProductSection'
import Footer from '../includes/Footer'

function Product() {
  return (
    <div className='page'>
        <Header />
        <ProductHeaderBanner />
        <ProductSection />
        <Footer />
    </div>
  )
}

export default Product