import React from 'react';

function TermConditionSection() {
    
  return (
        
        <div className="site-main">

            <section className="prt-row about-section clearfix">
                <div className="container" style={{marginTop: '100px'}}>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="res-1400-pl-15 res-1199-ml-30 res-991-pt-30 pl-15 pt-25 res-480-ml-0 res-480-pl-0">
                                
                                <div className="section-title">
                                    <div className="">
                                        <h3 style={{fontSize: "20px"}}>Terms &amp; Conditions</h3>
                                        {/* <h2 className="title">Our mission is to put an electric vehicle charge</h2> */}
                                    </div>
                                    <div className="title-desc">
                                        <p style={{fontSize: "16px"}}>Welcome to C & N Green Energy Pvt Ltd. These Terms and Conditions (&quot;Terms&quot;) govern your use of  <a href='https://cnkbharat.com/' target='_blank' rel="noreferrer"><strong>www.cnkbharat.com</strong></a>, so please read them carefully. By accessing or using the Website, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you may not access the Website.</p>
                                        
                                        <h4>Use of the Website</h4>
                                        <ul>
                                            <li>You must be at least 18 years old to use this Website. By using the Website, you represent and warrant that you are at least 18 years old.</li>
                                            <li>You agree to use the Website only for lawful purposes and in a manner consistent with all applicable laws and regulations.</li>
                                            <li>You agree not to engage in any activity that interferes with or disrupts the functioning of the website.</li>
                                        </ul>

                                        <h4>Intellectual Property</h4>
                                        <ul>
                                            <li>The content on the Website, including but not limited to text, graphics, logos, images, audio clips, and software, is owned or licensed by us and is protected by copyright, trademark, and other intellectual property laws.</li>
                                            <li>You may not modify, copy, reproduce, republish, upload, post, transmit, or distribute any content from the Website without our prior written consent.</li>
                                        </ul>

                                        <h4>User Accounts</h4>
                                        <ul>
                                            <li>You may be required to create an account to access certain features of the Website. You are responsible for maintaining the confidentiality of your account information, including your username and password.</li>
                                            <li>You agree to provide accurate, current, and complete information when creating your account and to update your information as necessary to keep it accurate, current, and complete.</li>
                                            <li>You are solely responsible for all activities that occur under your account, and you agree to notify us immediately of any unauthorized use of your account or any other breach of security.</li>
                                        </ul>

                                        <h4>Privacy</h4>
                                        <ul>
                                            <li>Your use of the Website is subject to our Privacy Policy, which is incorporated into these Terms by reference. By using the Website, you consent to the collection and use of your information as described in the Privacy Policy.</li>
                                        </ul>

                                        <h4>Disclaimer of Warranties</h4>
                                        <ul>
                                            <li>The Website is provided on an &quot;as is&quot; and &quot;as available&quot; basis, without any warranties of any kind, express or implied. We do not warrant that the Website will be uninterrupted or error-free, that defects will be corrected, or that the Website or the servers that make it available are free of viruses or other harmful components.</li>
                                        </ul>

                                        <h4>Limitation of Liability</h4>
                                        <ul>
                                            <li>To the fullest extent permitted by applicable law, we will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your access to or use of or inability to  access or use the Website; (b) any conduct or content of any third party on the Website; or (c) unauthorized access, use, or alteration of your transmissions or content.</li>
                                        </ul>

                                        <h4>Governing Law</h4>
                                        <ul>
                                            <li>These Terms shall be governed by and construed in accordance with the laws of Kolkata, without regard to its conflict of law principles.</li>
                                        </ul>

                                        <h4>Changes to Terms</h4>
                                        <ul>
                                            <li>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If we make any material changes to these Terms, we will notify you by posting the updated Terms on the Website. Your continued use of the Website after any such changes constitutes your acceptance of the new Terms.</li>
                                        </ul>

                                        <h4>Contact Us</h4>
                                        <ul>
                                            <li>If you have any questions about these Terms, please contact us at <strong>cngepl@cnkbharat.com</strong></li>
                                        </ul>
                                        <p>By making a purchase on the website, you acknowledge that you have read, understood, and agree to the terms of this Refunds/Cancellation Policy.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            

        </div>
  )
}

export default TermConditionSection