import {React, useState, useEffect, useRef} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function ContactSection() {
    const initialValue = { username: "", email: "", number: "", message: ""};
    const [formValues, setFormValues] = useState(initialValue);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({ ...formValues, [name]: value });
        // console.log(formValues)
    }

    const formRef = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    }

    const notify = () => toast.success("Congratulation, message has sent successfully");

    useEffect(() => {
        let url = "https://api.cnkbharat.com/api/contact-mail";
        if(Object.keys(formErrors).length === 0 && isSubmit) {
            axios.post(url, {
                name: document.getElementById('username').value,
                email: document.getElementById('email').value,
                mobile: document.getElementById('number').value,
                message: document.getElementById('message').value,
            })
            .then(() => {
                formRef.current.reset();
                document.getElementById('username').value='';
                document.getElementById('email').value='';
                document.getElementById('number').value='';
                document.getElementById('message').value='';
                notify();
            });
            
        }
    }, [formErrors]);


    const validate = (values) => {
        const errors = {};
        // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if(!values.username) {
            errors.username = "Name is required";
        }
        if(!values.email) {
            errors.email = "Email is required";
        }
        if(!values.number) {
            errors.number = "Number is required";
        }
        if(!values.message) {
            errors.message = "Message is required";
        }

        return errors
    }
    return (
            <div className="site-main">

                <section className="prt-row contact-us clearfix">
                    <div className="container">
                        <div className="row prt-vertical_sep">
                            <div className="col-lg-6 col-md-12">
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>QUICK CONTACT</h3>
                                        <h2 className="title" style={{'fontSize': '30px'}}>Get In Touch With Us Feel Free To <span> Write Us </span></h2>
                                    </div>
                                    <div className="title-desc">
                                        <p>For any Technical Issues don’t Hesitate to Contact Our Service Engineers.</p>
                                    </div>
                                </div>
                                
                                <div className="featured-icon-box icon-align-before-content icon-ver_align-top">
                                    <div className="featured-icon">
                                        <div className="prt-icon prt-icon_element-color-skincolor prt-icon_element-onlytxt prt-icon_element-size-lg prt-icon_element-style-rounded">
                                            <i className="fa-sharp fa-solid fa-location-dot"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Location</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>C & N Green Energy Pvt. Ltd.</p>
                                            <p>4, Pashupati Bhattacharjee Road,</p>
                                            <p>Kolkata - 700034, West Bengal, India</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="prt-horizontal_sep width-100 mt-25 mb-20 mr-40 res-991-mr-0"></div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="featured-icon-box icon-align-before-content icon-ver_align-top icon-flip-hover">
                                            <div className="featured-icon">
                                                <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-color-skincolor prt-icon_element-size-lg">
                                                    <i className="fa-solid fa-headset"></i>
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3>Quick Contact</h3>
                                                </div>
                                                <h3 className="fs-18 mb-0">Phone:</h3>
                                                <p>+91 9051257814</p>
                                                <h3 className="fs-18 mb-0">Email:</h3>
                                                <a href="mailto:info@example.com"><strong>cngepl@cnkbharat.com</strong></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="featured-icon-box icon-align-before-content icon-ver_align-top icon-flip-hover">
                                            <div className="featured-icon">
                                                <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-color-skincolor prt-icon_element-size-lg">
                                                    <i className="fa-solid fa-stopwatch"></i>
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3>Opening Hrs</h3>
                                                </div>
                                                <h3 className="fs-18 mb-0">Mon - Sat:</h3>
                                                <p>09:30 am to 06.00 pm</p>
                                                <h3 className="fs-18 mb-0">Sunday :</h3>
                                                <p>Closed</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 pl-60 res-991-pl-15 res-991-mt-20">
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>CONTACT US</h3>
                                        <h2 className="title" style={{'fontSize': '30px'}}>Send Your <span> Message</span></h2>
                                    </div>
                                    <div className="title-desc">
                                        <p>Our team is ready to answer all your questions.</p>
                                    </div>
                                </div>
                                <div className=" prt-bgcolor-white res-991-margin_right0 mt-20">
                                    {/* <pre> {JSON.stringify(formValues, undefined, 2)} </pre> */}
                                    <form ref={formRef} id="contact_form" className="wrap-form contact_form padding_top15" onSubmit={ handleSubmit }>
                                        <div className="row prt-boxes-spacing-10px">
                                            <div className="col-sm-6 prt-box-col-wrapper">
                                                <label>
                                                    <span className="text-input margin_bottom0"><input name="username" id="username" type="text" value= {formValues.username} placeholder="Your Name*" onChange={handleChange} /></span>
                                                </label>
                                            <p className='text-danger'><strong>{ formErrors.username }</strong></p>
                                            </div>
                                            <div className="col-sm-6 prt-box-col-wrapper">
                                                <label>
                                                    <span className="text-input margin_bottom0"><input name="email" id="email" type="text" value={formValues.email} placeholder="Email Address*" onChange={handleChange} /></span>
                                                </label>
                                            <p className='text-danger'><strong>{ formErrors.email }</strong></p>
                                            </div>
                                            <div className="col-sm-12 prt-box-col-wrapper">
                                                <label>
                                                    <span className="text-input margin_bottom0"><input name="number" id="number" type="text" value={formValues.number} onChange={handleChange} placeholder="Phone Number" /></span>
                                                </label>
                                            </div>
                                            <p className='text-danger'><strong>{ formErrors.number }</strong></p>
                                            <div className="col-sm-12 prt-box-col-wrapper">
                                                <label>
                                                    <span className="text-input margin_bottom0"><textarea name="message" id="message" cols="40" rows="5" placeholder="Your Message" aria-required="true" onChange={handleChange} defaultValue={formValues.message}></textarea></span>
                                                </label>
                                            </div>
                                            <p className='text-danger'><strong>{ formErrors.message }</strong></p>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <button className="submit prt-btn prt-btn-size-md prt-btn-shape-square prt-btn-style-fill prt-btn-color-skincolor w-100" type="submit">Send Now!</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                <ToastContainer />
                </section>
            </div>
    )
}

export default ContactSection