// import Home from './pages/home/Home';
// import Aboutus from './pages/aboutus/Aboutus';
// import Services from './pages/services/Service';
// import Contactus from './pages/contactus/Contactus'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import  React  from 'react';
import Web from './Web';
import ReactGA from "react-ga";
import './App.css';

const TRACKING_ID = "UA-163860306-1"; //OUR TRACKING ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div className="App">
      <Web />
      <TawkMessengerReact  className='fixed-bottom' style={{ bottom: '10px', left: '10px' }} propertyId="64c53d2f94cf5d49dc673169"  widgetId="1h6h7e1eo"/>

      
    </div>
  );
}

export default App;
